@import "~styles/config";

.dashboardWrapper{
  padding: 24px 16px;
    &__text{
      color: #fff;
      font-size: 28px;
      font-family: $font-family;
      font-weight: $font-semibold;
      margin-top: 8px;
      margin-bottom: 24px;
      &__highlight{
        color: $color-highlight;
        text-decoration: none;
      }
    }
    &__textInput{
      input{
        color: $color-highlight;
        background: transparent;
        outline: none;
        border: none;
        padding: 0;
        margin: 0;
        font-size: 28px;
        font-family: $font-family;
        font-weight: $font-semibold;
        &::-ms-clear {display: none;}
        &::placeholder{
          color: $color-highlight;
          opacity: .3;
        }
      }
    }
}